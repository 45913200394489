import React from 'react';
import Slideshows from "./Slideshows.js"
//import Slidess from "./Slidess.js"
function Main(){
    

    return(
        <div id="Main" className="main">
            <div className="sectionsWrap">
                <Slideshows />
                
            </div>
            <div className="sectionsWrap2">
                <div className="section" id="contatti">
                    <div className="corpo1">
                        CONTATTI
                    </div>
                    <div className="corpo2">
                        <a className="mail" href="mailto:ab-hamid-@hotmail.it">ab-hamid-@hotmail.it</a> <br/>
                        <a className="mail" href="mailto:abdelhamid.jyate@pec.it">abdelhamid.jyate@pec.it</a> <br/>
                        Tel. +39 339 24 66 021 <br/>
                    </div>
                </div>
            </div>
            
            
        </div>
        
     );
}
export default Main;