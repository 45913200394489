import React from 'react';
import { HashLink } from 'react-router-hash-link';



function Navigation() {
  return (
    <div className="navbar">
        <div className="navbar_logoWrap">
          <div className="navbar__item">Ditta Jyate Abdelhamid</div>
        </div>
        <div className="navbar_linksWrap">
          <HashLink className="navbar__item link" to="/#contatti">Contatti</HashLink>
          
        </div>
    </div>
  );
}
export default Navigation;