import React from "react"


const img = [{image: "/images/foto1.jpg"}, 
{image:"/images/foto2.jpg"},
{image: "/images/foto3.jpg"}
];
const delay = 4000;

function Slideshows() {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
        () =>
            setIndex((prevIndex) =>
            prevIndex === img.length - 1 ? 0 : prevIndex + 1
            ),
        delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div className="slideshow">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {img.map((bg, ind) => {
                    return (
                    <div
                        className={ind === index ? 'slide active' : 'slide'}
                        key={ind}
                        // style={{backgroundImage:bg}}
                    >
                        {ind === index &&(
                        <img className="imagex" src={bg.image} alt="travel" />
                        )}
                    </div>
                    );
                })}
            </div>

            <div className="slideshowDots">
                {img.map((_, idx) => (
                    <div
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                        setIndex(idx);
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
}
export default Slideshows;