import React from 'react';



function Footer() {
    return(
        <div className="footer">
            <div className="wrapf">
                <div className="secWrap">
                    <div className="titoloF">DITTA JYATE ABDELHAMID </div>
                    <div className="sottotitolof">P.IVA: IT04010710269</div>
                    <div className="sottotitolof">Sede legale</div>
                        <div className="testof">
                            Via Roma 142 <br/>
                            31047 Ponte di Piave (TV)
                        </div>
                </div>
                <div className="secWrap">
                    <div className="frasetta">
                    La ns. impresa nel corso dell'esercizio 2020 ha ricevuto contributi rientranti 
                    nel regime degli aiuti di stato e/o de minimis che sono riportati nel sito 
                    internet del registro nazionale degli aiuti di stato (RNA) a cui pertanto si fa 
                    rinvio.
                    </div>

                </div>
            </div>

        </div>
    );
}
export default Footer;