import './App.css';
import Main from "./components/Main"
import Slideshows from "./components/Slideshows"
import Navigation from "./components/Navigation"
import Footer from "./components/Footer"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="App-header">

      <Router>
        <Navigation />
        <Routes>
          <Route exact path='/' element={<Main />} />
          <Route path='/Slideshows' element={<Slideshows />} />
        </Routes>
        
        <Footer />
        
        
      </Router>

        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          BANANA
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
